<template>
  <div id="clients">
    <div class="container-fluid">
      <div class="wallet-section" v-if="canAccess(['add-to-wallet-client'])">
        <b-modal id="wallet-modal" hide-header-close :cancel-title="$t('close')" :ok-title="$t('save')"
                 :title="$t('wallet')" @ok="postWallet">
          <b-form class="d-grid grid-cols-1 gap-5">
            <b-row>
              <b-col cols="12">
                <div class="feild">
                  <label for="">{{ $t('wallet-amount') }}</label>
                  <b-form-input
                    type="number"
                    min="1"
                    :placeholder="$t('wallet-amount')"
                    v-model="wallet.amount"
                    required
                  ></b-form-input>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="feild">
                  <label for="">{{ $t('transaction-type') }}</label>
                  <b-form-select v-model="wallet.type" :options="insertMonyType"></b-form-select>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
        <b-modal id="send-notification-modal" hide-header-close :cancel-title="$t('close')" :ok-title="$t('save')" :title="$t('send_notification')" @ok="postNotification">
          <b-form class="d-grid grid-cols-1 gap-5">
            <b-row>
              <b-col cols="12">
                <div class="feild">
                  <label for="">{{ $t('title') }}</label>
                  <b-form-input
                    v-model="notification.title"
                    required
                  ></b-form-input>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="feild">
                  <label for="">{{ $t('content') }}</label>
                  <b-form-input
                    v-model="notification.body"
                    required
                  ></b-form-input>
                </div>
              </b-col>
              <b-col>
                <label for="">{{ $t('notification_type') }}</label>
                <b-form-select v-model="notification.client_type" class="mb-3">
                  <b-form-select-option value="all">
                    {{ $t('all') }}
                  </b-form-select-option>
                  <b-form-select-option value="some" :disabled="selected.length < 1">
                    {{ $t('only_selected_client') }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
      <!-- Add Edit Section -->
      <div class="add-section" v-if="canAccess(['update-client' , 'store-client'])">
        <b-modal id="clients-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')" :ok-title="$t('save')" :title="$t('sidebar.clients')" @ok="submit">
          <b-form class="d-grid grid-cols-1 gap-5">
            <b-row>
              <b-col cols="12">
                <div class="feild">
                  <label for="">{{ $t('name') }}</label>
                  <b-form-input
                    type="text"
                    placeholder=""
                    v-model="payload.name"
                    required
                  ></b-form-input>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="feild">
                  <label for="">{{ $t('phone') }}</label>
                  <b-form-input
                    type="text"
                    placeholder=""
                    v-model="payload.phone"
                    required
                  ></b-form-input>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="feild">
                  <label for="">{{ $t('email') }}</label>
                  <b-form-input
                    type="text"
                    placeholder=""
                    v-model="payload.email"
                    required
                  ></b-form-input>
                </div>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="4">
                    <div class="feild">
                      <label for="">{{ $t('country') }}</label>
                      <b-form-input
                        type="text"
                        placeholder=""
                        v-model="payload.country"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col sm="12" md="4">
                    <div class="feild">
                      <label for="">{{ $t('city') }}</label>
                      <b-form-input
                        type="text"
                        placeholder=""
                        v-model="payload.city"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col sm="12" md="4">
                    <div class="feild">
                      <label for="">{{ $t('passcode') }}</label>
                      <b-form-input
                        type="text"
                        placeholder=""
                        v-model="payload.passcode"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="12">
                <div class="feild">
                  <label for="">{{ $t('password') }}</label>
                  <b-form-input
                    type="text"
                    placeholder=""
                    v-model="payload.password"
                    required
                  ></b-form-input>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
      <!-- Filters Section -->
      <div class="filters-section">
        <iq-card class="w-100">
          <template v-slot:body>
            <div class="filter-container" @change="filtering">
              <b-form-input
                type="text"
                :placeholder="$t('filter-name')"
                v-model="filterData.name"
                required
              ></b-form-input>
            </div>
          </template>
        </iq-card>
      </div>
      <!-- Tabel Section -->
      <div class="table-sections">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('clients-list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button v-if="canAccess(['store-client'])" variant="secondary" @click="$bvModal.show('send-notification-modal')" size="sm" class="mx-2">
              {{
                $t('send_notification')
              }}
            </b-button>
            <b-button v-if="canAccess(['store-client'])" variant="primary" @click="addInit" size="sm">
              {{
                $t('add')
              }}
            </b-button>
          </template>
          <template v-slot:body>
            <!-- {{ selected }} -->
            <b-table responsive :items="clients.data" :fields="fields" class="mb-0 table-borderless" v-if="canAccess(['get-client' , 'get-client' , 'add-to-wallet-client' , 'update-client' , 'delete-client'])">
              <template v-slot:cell(checkbox)="data">
                <b-form-checkbox
                  :checked="selected.includes(data.item.id)"
                  @change="toggleSelected(data.item.id)"
                />
              </template>
              <template v-slot:cell(actions)="data">
                <div class="actions-list d-flex align-items-center">
                  <b-button v-if="canAccess(['add-to-wallet-client'])" v-b-tooltip.top="$t('add-wallet')" variant=" iq-bg-success" @click="waletInit(data.item)" size="md"><i class="ri-wallet-line p-0"></i></b-button>
                  <b-button v-b-tooltip.top="$t('transaction-history')" variant=" iq-bg-primary"  size="md" @click="$router.push(`/clients-history/${data.item.id}`)"><i class="fas fa-history p-0"></i></b-button>
                </div>
              </template>
            </b-table>
          </template>
        </iq-card>
      </div>
      <!-- Pagination Section -->
      <div class="pagination">
        <iq-card class="w-100 d-flex justify-content-center">
          <template v-slot:body>
            <b-pagination
              @change="getClients(filterData)"
              v-model="filterData.page"
              :total-rows="clients.total"
              :per-page="clients.per_page"
            ></b-pagination>
          </template>
        </iq-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'governorates',
  data () {
    return {
      selected: [],
      filterData: {
        page: 1,
        name: ''
      },

      wallet: {
        id: '',
        amount: '',
        type: 0
      },
      payload: {
        name: '',
        email: '',
        phone: '',
        city: '',
        password: '',
        country: '',
        passcode: ''
      },
      notification: {
        title: '',
        body: '',
        client_type: 'all'
      }
    }
  },
  computed: {
    insertMonyType () {
      return [
        {
          text: this.$t('deposit'),
          value: 0
        },
        {
          text: this.$t('draw'),
          value: 1
        }
      ]
    },
    fields () {
      return [
        {
          label: '#',
          key: 'checkbox'
        },
        {
          label: this.$t('name'),
          key: 'name'
        },
        {
          label: this.$t('email'),
          key: 'email'
        },
        {
          label: this.$t('phone'),
          key: 'phone'
        },
        {
          label: this.$t('country'),
          key: 'country'
        },
        {
          label: this.$t('city'),
          key: 'city'
        },
        {
          label: this.$t('wallet'),
          key: 'wallet'
        },
        {
          label: this.$t('percentage'),
          key: 'percentage'
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('created-by'),
          key: 'created_by.name'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    toggleSelected (id) {
      if (this.selected.includes(id)) {
        this.selected = this.selected.filter(i => i !== id)
      } else {
        this.selected.push(id)
      }
    },
    filtering () {
      this.filterData.page = 1
      this.getGovernorates(this.filterData)
    },
    addInit () {
      this.$bvModal.show('clients-modal')
    },
    waletInit (data) {
      this.wallet.id = data.id
      this.$bvModal.show('wallet-modal')
    },
    postWallet (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.addWallet({
        id: this.wallet.id,
        data: {
          amount: this.wallet.amount,
          type: this.wallet.type
        }
      }).then(() => {
        this.getClients()
        this.wallet = this.resetObject(this.wallet)
        this.$bvModal.hide('wallet-modal')
      })
    },
    postNotification () {
      const form = {
        ...this.notification,
        ...this.notification.client_type === 'some' ? { clientIds: this.selected } : {}
      }
      this.$store.dispatch('sendNotificationToClient', form)
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeGovernorates(id)
      })
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.payload.id) {
        this.addClients(this.payload).then(() => {
          this.$bvModal.hide('clients-modal')
          this.payload = this.resetObject(this.payload)
          this.getClients()
        })
      } else {
        this.payload._method = 'PUT'
        this.updateClients(this.payload).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$bvModal.hide('clients-modal')
          this.getClients()
        })
      }
    },
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.payload = this.resetObject(this.payload)
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getClients(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
    this.getClients()
  }
}
</script>
